.banner {
    &__header {
        background: $accent;
        z-index: 99999;
        right: 0;
        left: 0;
        &--inner {
            padding-top: 10px;
        }
        .brand {
            padding-top: 5px;
            display: block;
            img {
                max-width: 178px;
            }
        } 
        .search-form {

            .input-group {
                width: 100% !important;
                .header-search {
                    background-color: transparent;
                    border-bottom-left-radius: 25px;
                    border-top-left-radius: 25px;
                    border-color: $light;
                    color: white!important;
                    font-size:$rem13;          
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: $light;
                        font-size: $rem13;
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: $light;
                        font-size: $rem13;
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: $light;
                        font-size: $rem13;
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: $light;
                        font-size: $rem13;
                    }
                }
                .btn-search-header {
                    border-bottom-right-radius: 25px;
                    border-top-right-radius: 25px;
                    padding-left: 20px;
                    padding-right: 20px;

                    &:hover {
                        background-color: $color_1;
                        color:$light;

                    }
                }
            }
        }
    }
}



.header-mobile {
    display: none;
}
.label-lg {
    display: none;
}
@media (max-width:991px) {
    .header-desktop {
        display:none;
    }

}
@media (min-width:1199px) {
    .label-lg {
        display: inline-block;
    }




}


.sticky-nav {
    background: rgba(234, 193, 74, 0.9) !important;
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index:9;
    -webkit-backdrop-filter: saturate(180%) blur(3px);
    backdrop-filter: saturate(180%) blur(3px);
}
