/*


      /^         /^^ ^^      /^^         /^       /^^^^^^^        /^^^^     
     /^ ^^     /^^    /^^ /^^   /^^     /^ ^^     /^^    /^^    /^^    /^^  
    /^  /^^     /^^      /^^           /^  /^^    /^^    /^^  /^^        /^^
   /^^   /^^      /^^    /^^          /^^   /^^   /^ /^^      /^^        /^^
  /^^^^^^ /^^        /^^ /^^         /^^^^^^ /^^  /^^  /^^    /^^        /^^
 /^^       /^^ /^^    /^^ /^^   /^^ /^^       /^^ /^^    /^^    /^^     /^^ 
/^^         /^^  /^^ ^^     /^^^^  /^^         /^^/^^      /^^    /^^^^     
                                                                            
________________________________________________________________________________   

BOOTSPACER 1.0
Created on: 9-Nov-2017, 00.26.36
Author: ascaro.it
________________________________________________________________________________


*/


// Padding classes

.air {padding-top: 100px;padding-bottom: 100px;}
.air-top {padding-top: 100px;}
.air-bottom {padding-bottom: 100px;}
.air-50 {padding-top: 50px;padding-bottom: 50px;}
.air-50-top {padding-top: 50px;}
.air-50-bottom {padding-bottom: 50px;}
.air-30 {padding-top: 30px;padding-bottom: 30px;}
.air-30-top {padding-top: 30px;}
.air-30-bottom {padding-bottom: 30px;}
.air-15 {padding-top: 15px;padding-bottom: 15px;}
.air-15-top {padding-top: 15px;}
.air-15-bottom {padding-bottom: 15px;}



// Margin classes

.space {margin-top: 100px;margin-bottom: 100px;}
.space-top {margin-top: 100px;}
.space-bottom {margin-bottom: 100px;}
.space-50 {margin-top: 50px;margin-bottom: 50px;} 
.space-50-top {margin-top: 50px;}
.space-50-bottom {margin-bottom: 50px;}
.space-30 {margin-top: 30px;margin-bottom: 30px;}
.space-30-top {margin-top: 30px;}
.space-30-bottom {margin-bottom: 30px;}
.space-15 {margin-top: 15px;margin-bottom: 15px;} 
.space-15-top {margin-top: 15px;}
.space-15-bottom {margin-bottom: 15px;}


// Reset 

.no-air {padding-left: 0px !important; padding-right: 0px !important;}
.no-air-left {padding-left: 0px !important; }
.no-air-right {padding-right: 0px !important;}

.no-space {margin-left: 0px !important; margin-right: 0px !important;}
.no-space-left {margin-left: 0px !important; }
.no-space-right {margin-right: 0px !important;}
.no-space-g {margin:0px !important;}


// Display

.inline {display: inline-block;}
.center {margin: 0 auto;text-align: center;}


// Flex

.wrapper_flex-center-center {display: flex;align-items: center;justify-content: center;height:100%;}
.wrapper_flex-center-top {display: flex;align-items: flex-start;justify-content: center;height:100%;}
.wrapper_flex-center-bottom {display: flex;align-items: flex-end;justify-content: center;height:100%;}
.flex_unit {flex:1;}


@media only screen and (min-width: 1200px){

    
    .air-mobile-lg {padding-top: 100px;padding-bottom: 100px;}
    .air-mobile-lg-top {padding-top: 100px;}
    .air-mobile-lg-bottom {padding-bottom: 100px;}
    .air-mobile-lg-50 {padding-top: 50px;padding-bottom: 50px;}
    .air-mobile-lg-50-top {padding-top: 50px;}
    .air-mobile-lg-50-bottom {padding-bottom: 50px;}
    .air-mobile-lg-30 {padding-top: 30px;padding-bottom: 30px;}
    .air-mobile-lg-30-top {padding-top: 30px;}
    .air-mobile-lg-30-bottom {padding-bottom: 30px;}

    // Margin classes

    .space-lg {margin-top: 100px;margin-bottom: 100px;}
    .space-lg-top {margin-top: 100px;}
    .space-lg-bottom {margin-bottom: 100px;}
    .space-lg-50 {margin-top: 50px;margin-bottom: 50px;}
    .space-lg-50-top {margin-top: 50px;}
    .space-lg-50-bottom {margin-bottom: 50px;}
    .space-lg-30 {margin-top: 30px;margin-bottom: 30px;}
    .space-lg-30-top {margin-top: 30px;}
    .space-lg-30-bottom {margin-bottom: 30px;}
    .space-lg-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-lg-15-top {margin-top: 15px;}
    .space-lg-15-bottom {margin-bottom: 15px;}



    // Reset

    .no-air-lg {padding-left: 0px !important; padding-right: 0px !important;}
    .no-air-lg-left {padding-left: 0px !important; }
    .no-air-lg-right {padding-right: 0px !important;}

    .no-space-lg {margin-left: 0px !important; margin-right: 0px !important;}
    .no-space-lg-left {margin-left: 0px !important; }
    .no-space-lg-right {margin-right: 0px !important;}

    
}


@media only screen and (min-width: 992px)and (max-width:1199px){



    .air {padding-top: 70px;padding-bottom: 70px;}
    .air-mobile-sm {padding-top: 70px;padding-bottom: 70px;}
    .air-mobile-sm-top {padding-top: 70px;}
    .air-mobile-sm-bottom {padding-bottom: 70px;}
    .air-mobile-sm-50 {padding-top: 35px;padding-bottom: 35px;}
    .air-mobile-sm-50-top {padding-top: 35px;}
    .air-mobile-sm-50-bottom {padding-bottom: 35px;}
    .air-mobile-sm-30 {padding-top: 30px;padding-bottom: 30px;}
    .air-mobile-sm-30-top {padding-top: 30px;}
    .air-mobile-sm-30-bottom {padding-bottom: 30px;}

    // Margin classes

    .space-sm {margin-top: 70px;margin-bottom: 70px;}
    .space-sm-top {margin-top: 70px;}
    .space-sm-bottom {margin-bottom: 70px;}
    .space-sm-50 {margin-top: 35px;margin-bottom: 35px;}
    .space-sm-50-top {margin-top: 35px;}
    .space-sm-50-bottom {margin-bottom: 50px;}
    .space-sm-30 {margin-top: 30px;margin-bottom: 30px;}
    .space-sm-30-top {margin-top: 30px;}
    .space-sm-30-bottom {margin-bottom: 30px;}
    .space-sm-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-sm-15-top {margin-top: 15px;}
    .space-sm-15-bottom {margin-bottom: 15px;}





    .air {padding-top: 75px;padding-bottom: 75px;}
    .air-top {padding-top: 75px;}
    .air-bottom {padding-bottom: 75px;}
    .air-50 {padding-top: 40px;padding-bottom: 40px;}
    .air-50-top {padding-top: 40px;}
    .air-50-bottom {padding-bottom: 40px;}
    .air-30 {padding-top: 20px;padding-bottom: 20px;}
    .air-30-top {padding-top: 20px;}
    .air-30-bottom {padding-bottom: 20px;}
    .air-15 {padding-top: 15px;padding-bottom: 15px;}
    .air-15-top {padding-top: 15px;}
    .air-15-bottom {padding-bottom: 15px;}



    // Margin classes

    .space {margin-top: 70px;margin-bottom: 70px;}
    .space-top {margin-top: 70px;}
    .space-bottom {margin-bottom: 70px;}
    .space-50 {margin-top: 40px;margin-bottom: 40px;} 
    .space-50-top {margin-top: 40px;}
    .space-50-bottom {margin-bottom: 40px;}
    .space-30 {margin-top: 30px;margin-bottom: 20px;}
    .space-30-top {margin-top: 20px;}
    .space-30-bottom {margin-bottom: 20px;}
    .space-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-15-top {margin-top: 15px;}
    .space-15-bottom {margin-bottom: 15px;}





    // Reset

    .no-air-sm {padding-left: 0px !important; padding-right: 0px !important;}
    .no-air-sm-left {padding-left: 0px !important; }
    .no-air-sm-right {padding-right: 0px !important;}

    .no-space-sm {margin-left: 0px !important; margin-right: 0px !important;}
    .no-space-sm-left {margin-left: 0px !important; }
    .no-space-sm-right {margin-right: 0px !important;}



}



@media only screen and (min-width: 768px)and (max-width:991px){


    .air-mobile-sm {padding-top: 50px;padding-bottom: 50px;}
    .air-mobile-sm-top {padding-top: 50px;}
    .air-mobile-sm-bottom {padding-bottom: 50px;}
    .air-mobile-sm-50 {padding-top: 25px;padding-bottom: 25px;}
    .air-mobile-sm-50-top {padding-top: 25px;}
    .air-mobile-sm-50-bottom {padding-bottom: 25px;}
    .air-mobile-sm-30 {padding-top: 20px;padding-bottom: 20px;}
    .air-mobile-sm-30-top {padding-top: 20px;}
    .air-mobile-sm-30-bottom {padding-bottom: 20px;}

    // Margin classes

    .space-sm {margin-top: 50px;margin-bottom: 50px;}
    .space-sm-top {margin-top: 50px;}
    .space-sm-bottom {margin-bottom: 50px;}
    .space-sm-50 {margin-top: 25px;margin-bottom: 25px;}
    .space-sm-50-top {margin-top: 25px;}
    .space-sm-50-bottom {margin-bottom: 50px;}
    .space-sm-30 {margin-top: 20px;margin-bottom: 20px;}
    .space-sm-30-top {margin-top: 20px;}
    .space-sm-30-bottom {margin-bottom: 20px;}
    .space-sm-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-sm-15-top {margin-top: 15px;}
    .space-sm-15-bottom {margin-bottom: 15px;}



    .air {padding-top: 50px;padding-bottom: 50px;}
    .air-top {padding-top: 50px;}
    .air-bottom {padding-bottom: 50px;}
    .air-50 {padding-top: 30px;padding-bottom: 30px;}
    .air-50-top {padding-top: 30px;}
    .air-50-bottom {padding-bottom: 30px;}
    .air-30 {padding-top: 15px;padding-bottom: 15px;}
    .air-30-top {padding-top: 15px;}
    .air-30-bottom {padding-bottom: 15px;}
    .air-15 {padding-top: 15px;padding-bottom: 15px;}
    .air-15-top {padding-top: 15px;}
    .air-15-bottom {padding-bottom: 15px;}



    // Margin classes

    .space {margin-top: 50px;margin-bottom: 50px;}
    .space-top {margin-top: 50px;}
    .space-bottom {margin-bottom: 50px;}
    .space-50 {margin-top: 30px;margin-bottom: 30px;} 
    .space-50-top {margin-top: 30px;}
    .space-50-bottom {margin-bottom: 30px;}
    .space-30 {margin-top: 20px;margin-bottom: 20px;}
    .space-30-top {margin-top: 20px;}
    .space-30-bottom {margin-bottom: 20px;}
    .space-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-15-top {margin-top: 15px;}
    .space-15-bottom {margin-bottom: 15px;}



    // Reset

    .no-air-sm {padding-left: 0px !important; padding-right: 0px !important;}
    .no-air-sm-left {padding-left: 0px !important; }
    .no-air-sm-right {padding-right: 0px !important;}

    .no-space-sm {margin-left: 0px !important; margin-right: 0px !important;}
    .no-space-sm-left {margin-left: 0px !important; }
    .no-space-sm-right {margin-right: 0px !important;}


    .mobile-center {  text-align: center !important; margin: 0 auto;}
    
}


@media only screen and (max-width:767px){


    .air {padding-top: 30px;padding-bottom: 30px;}
    .air-top {padding-top: 30px;}
    .air-bottom {padding-bottom: 30px;}
    .air-50 {padding-top: 20px;padding-bottom: 20px;}
    .air-50-top {padding-top: 20px;}
    .air-50-bottom {padding-bottom: 20px;}
    .air-30 {padding-top: 15px;padding-bottom: 15px;}
    .air-30-top {padding-top: 15px;}
    .air-30-bottom {padding-bottom: 15px;}



    // Margin classes

    .space {margin-top: 30px;margin-bottom: 30px;}
    .space-top {margin-top: 30px;}
    .space-bottom {margin-bottom: 30px;}
    .space-50 {margin-top: 20px;margin-bottom: 20px;} 
    .space-50-top {margin-top: 20px;}
    .space-50-bottom {margin-bottom: 20px;}




    .air-mobile-xs {padding-top: 30px;padding-bottom: 30px;}
    .air-mobile-xs-top {padding-top: 30px;}
    .air-mobile-xs-bottom {padding-bottom: 30px;}
    .air-mobile-xs-50 {padding-top: 35px;padding-bottom: 35px;}
    .air-mobile-xs-50-top {padding-top: 35px;}
    .air-mobile-xs-50-bottom {padding-bottom: 35px;}
    .air-mobile-xs-30 {padding-top: 30px;padding-bottom: 30px;}
    .air-mobile-xs-30-top {padding-top: 30px;}
    .air-mobile-xs-30-bottom {padding-bottom: 30px;}

    // Margin classes

    .space-xs {margin-top: 30px;margin-bottom: 30px;}
    .space-xs-top {margin-top: 30px;}
    .space-xs-bottom {margin-bottom: 30px;}
    .space-xs-50 {margin-top: 35px;margin-bottom: 35px;}
    .space-xs-50-top {margin-top: 35px;}
    .space-xs-50-bottom {margin-bottom: 30px;}
    .space-xs-30 {margin-top: 30px;margin-bottom: 30px;}
    .space-xs-30-top {margin-top: 30px;}
    .space-xs-30-bottom {margin-bottom: 30px;}
    .space-xs-15 {margin-top: 15px;margin-bottom: 15px;} 
    .space-xs-15-top {margin-top: 15px;}
    .space-xs-15-bottom {margin-bottom: 15px;}



    // Reset

    .no-air-xs {padding-left: 0px !important; padding-right: 0px !important;}
    .no-air-xs-left {padding-left: 0px !important; }
    .no-air-xs-right {padding-right: 0px !important;}

    .no-space-xs {margin-left: 0px !important; margin-right: 0px !important;}
    .no-space-xs-left {margin-left: 0px !important; }
    .no-space-xs-right {margin-right: 0px !important;}

    
    
    // Mobile
    
    .mobile-center {  text-align: center; margin: 0 auto;}
    
    
    .air-top-xs {
        padding-top:30px;
    }
    .air-bottom-xs {
        padding-bottom:30px;
    }
    
    .space-top-xs {
        margin-top:30px;
    }
    .space-bottom-xs {
        margin-bottom:30px;
    }

    
    
    
    
    
    
}

