.side {
    &-one {
       // border: 1px solid $breadcrumbs_divider;
    }
    
    .icon-side {
        font-size: 32px;
        color:$accent !important;
        margin-bottom:5px;
    }
}