.nl_menu {
    &__main {
        background:$color_1;
        padding: 6px 0 10px;
        .wrapper-nav {
            margin:0 auto;
        }
        .nav-primary {
            margin: 0 auto;

            ul.nav {
                li {
                    padding:0 5px 0 15px;

                    a {
                        color:$light;
                        font-family:$family-title;
                        font-size:$rem13;
                        font-weight:700;
                        text-transform:uppercase;
                        &:hover {
                            color:$darken-color_1;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &__news {
        border :1px solid $breadcrumbs_divider;
        ul {
            display: flex;
            justify-content: center;
            li {
                a {
                    padding:0 15px;
                    color:$dark;      
                    font-size:$rem13;
                    font-weight: 700;
                    font-family: $family-title;
                    text-transform: uppercase;              
                }
            }
        }

        &--white {
            hr {
                border-color:white;
            }
            ul {
                text-align: center;
                margin:0 auto;
                li {
                    a {
                        padding:0 15px;
                        color:$light;      
                        font-size:$rem13;
                        font-weight: 700;
                        font-family: $family-title;
                        text-transform: uppercase;              
                    }
                }
            }
        }
    }
    &__categorie-footer {
        ul {
            li {
                padding-bottom: 10px;
            }
        }
    }
    &__service {
        margin: 0 auto;
        /*        border-top:1px solid $breadcrumbs_divider;*/
        /*        border-bottom:1px solid $breadcrumbs_divider;*/
        ul {
            text-align: center;
            margin:0 auto;
            li {
                a {
                    font-size: 11px !important;

                    padding:0 15px;
                    color:$light;      
                    font-size:$rem13;
                    font-weight: 700;
                    font-family: $family-title;
                    text-transform: uppercase;              
                }
            }
        }

    }
}

.nl_social {
    ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        li {
            display: inline;
            flex-grow: 1;
            font-size: 32px;
            text-align: center;
        }
    }   
}

.navigation.pagination {

    .nav-links {

        border: 1px solid #dadada;
        padding: 0 0px 0 5px;
        width: 100%;

        .page-numbers {
            padding: 0px 15px;

            &:after {
                content: "";
                border-right: 1px solid #dadada;
                padding: 0 5px 0 5px;

            }
        }
    }
}