.nl_sidebar {
   
        ul {
            padding-left:0 !important;
            li{
                list-style-type: none;
                border-bottom: 1px solid rgba(0,0,0,.1);
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
        &__evento {
            a {
/*                display:block;*/
            }
        }

}