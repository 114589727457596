body {
    background-color: white;
    -webkit-font-smoothing: antialiased;
    font-family:'Source Sans Pro', sans-serif;
    
}
:focus {
    outline:none !important;
}

.main {
    padding: 0 !important;
}
h1,h2,h3,h4.h5,h6  {

font-family:'Montserrat', sans-serif;

}

a {
    color:#303d4d;
}

button.close {
    padding: 0;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    float: right;
    position: absolute;
    top: 0;
    right: 50px;
    font-size: 60px;
    color: white;
    opacity: 1;
    z-index: 9999999999;

    &:hover {
        color: #eac14a;
        opacity: 1;

    }
}

#breadcrumbs {
    span {color:white;}
    a {
        color:#ffc107;
    }
}

 

@media (max-width: 991px) {
    .wrap {
        padding-top: 70px;
    }
}

@mixin box_mese() {

    padding: 8px;
    background: #2c3546;
    color: #ffc107;
    font-weight: 700;
    text-transform: uppercase;
  }
  

.kenburns-top {
	-webkit-animation: kenburns-top 5s ease-out both;
	        animation: kenburns-top 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2018-6-14 22:34:48
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}


.flip-in-ver-right  {
	-webkit-animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-ver-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-6-14 22:40:4
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}


.bg_scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
}