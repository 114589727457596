.menu--mobile {
    position:fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: $accent;
    overflow-y: hidden;

    @include media-breakpoint-up(lg) {
        display: none;
    }
    @include media-breakpoint-up(xl) {
        display: none;
    }
    .logo-mobile {
        img {
            height: 70px;
            @media (max-width:768px){
                height: 60px;
                margin-top: 10px;
            }
        }
    }
    .btn--calendar {
        border-radius: 50%;
        font-size: 22px;
        padding: 10px 17px;
        margin-top: 10px;
        box-shadow: inset 0 0 0 2px #fff;
        margin-bottom: 10px;

    }
}

.btnMobile {
    z-index: 90000;
    position: fixed;
    top: 15px;
    color: white;
    background: transparent;
    border: none;
}

.mobileMenu {
    display: none;
    background-color: #212b38;
    padding: 20px;

    margin: 0;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
    overscroll-behavior-y: contain;
    ul {
        li {
            display: block;
            width: 100%;
            padding: 4px 0;
            a {
                display: block;
                font-size: 12px;
                color:$light;
            }
        }
    }

 



    .search-form {
        .input-group {
            width: 100% !important;
            height: 50px;
            .header-search {
                background-color: transparent;
                border-bottom-left-radius: 25px;
                border-top-left-radius: 25px;
                border-color: $light;
                color: white!important;
                font-size:14px;          
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: $light;
                    font-size: $rem13;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: $light;
                    font-size: $rem13;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: $light;
                    font-size: $rem13;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: $light;
                    font-size: $rem13;
                }
            }
            .btn-search-header {
                height: 50px;

                border-bottom-right-radius: 25px;
                border-top-right-radius: 25px;
                padding-left: 20px;
                padding-right: 20px;

                &:hover {
                    background-color: $color_1;
                    color:$light;

                }
            }
        }
    }
}

.burger-menu {
    position: relative;

    cursor: pointer;
    span {
        display: block;
        line-height: 0;
        width: 40px;
        height: 2px;
        margin-top: 8px;
        background-color: white;
    }
    .line1 {
        transform-origin: 84% 50%;
    }
    .line3 {
        transform-origin: 84% -50%;
    }
}

.burger-menu span {
    transition: all .12s ease-in-out;
    -webkit-transition: all .12s ease-in-out;
}

.burger-menu.menu-on .line2 {
    opacity: 0;
}

.burger-menu.menu-on .line1 {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    bottom: 0px;
}

.burger-menu.menu-on .line3 {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 0px;
}


.slider
{
    &--menu {
        background:$color_1;
        padding: 10px 5px;
        line-height: 1.2;

        .wrapper-nav {
            margin:0 auto;
        }

        .slick-next {
            right:15px;

            &::before {
                content: url('/wp-content/themes/nl_periperi/dist/right.svg');
                height: 30px;
            } 
        }

        .slick-slide {
            li.menu-item {
                text-align: center;

                a {
                    color:$light;
                    font-family:$family-title;
                    font-size:14px;
                    font-weight:700;
                    text-transform:uppercase;
                    &:hover {
                        color:$darken-color_1;
                        text-decoration: none;
                    }
                }
            }
        }

    }
}