.slider {
  &.slider__events {
    .slide {
      background-size: cover;
      background-position: center center;
      background-attachment: fixed;
      height: 485px;
      bottom: 15px;
      padding: 0 15px;
      ul.event-categories {
        padding: 2px 7px !important;
        font-family: $family-title;
        font-weight: 700;
        text-transform: uppercase;
        font-size: $rem12;
        margin-bottom: 0 !important;
        list-style: none;
        display: inline-block;
        float: right;
        border: 1px solid $color_1;
        padding: 5px;
        margin-top: 13px;
    
        a {
          color: $color_1;
        }
      }
      a {
        color: $light;
      }
      h3 {
        text-transform: uppercase;
      }
    }
  }
}

.bg_scroll {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  /*       mix-blend-mode: multiply;
 */
}
