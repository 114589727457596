// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$accent: #2a3544;
$accent-overlay: rgba(42,53,68,0.90);
 
$accent_light: #33658a;

$accent_dark:#3c3c3b;
$dark_overlay:rgba(60,60,60,0.5);

$light:#ffffff;
$light_overlay:rgba(255,255,255,0.5);

$color_1:#eac14a;
$darken-color_1:darken($color_1, 20% );

$color_2:#0aabb5;

 