.nl_single  {
    &--testata-event{
        background-color: #ececec;
        position: relative;
        overflow: hidden;

    }

    @keyframes bgscale {
        0% {
            transform: scale(1.1);
            filter: blur(2px);

        }

        100% {


        }
    }



    .event-image { 
        height: 382px;  
        box-shadow: 0 0px 83px 6px rgba(86, 86, 86, 0.55);
        background-size: cover;
        background-position: center center; 
        margin-top: -120px; 

        @media (max-width:767px) {
            margin-top: -40px;
            height: 170px;  

        }


        @media (min-width:992px) and (max-width:1199px) {
            height: 350px;
            h1 {
                font-size: 32px;
            }

        } 
    } 
    ul.event-categories {
        padding: 0;
        font-family: $family-title;
        font-weight: 700;  
        text-transform: uppercase;
        font-size: $rem11;
        margin-bottom: 0 !important;
        list-style: none; 
        display: block;
        float: none;
        border: none;
        padding: 5px;

        a {
            color: $color_2;
        }
    }

    .nl_sidebar__evento {
        @media(min-width:991px) {
            border-left: 1px solid #e8e8e8;
            padding-left:20px;

        }

    }

    .image-overlay {
        animation: bgscale 2s ;
        transform: scale(1.2);

        position: absolute; 
        top:0;
        left:0;
        right:0;
        bottom:0; 
        background-size:cover !important;
        background-attachment: fixed !important;
        background-position: center center !important;
        filter: blur(9px);
        /*        mix-blend-mode: luminosity;*/

        @media(max-width:768px) {
        top:-10px;
    background-size: cover !important; 
    background-attachment: scroll !important;


        }


        &.term {
            background-size:cover !important;
            background-attachment: fixed !important;
            background-position: center top !important;
            -webkit-filter: none !important;
            filter: none !important;
            opacity: 1;
            mix-blend-mode: unset; 
        }

        &.term-MUSICA {
            background: url(/wp-content/themes/nl_periperi/dist/images/terms/musica.jpg);
        }
        &.term-teatro    {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/teatro.jpg);

        }
        &.term-cinema    {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/cinema.jpg);

        }
        &.term-MUSEI {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/museo.jpg);

        }
        &.term-LIBRI {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/libreria.jpg);

        }
        &.term-LIFESTYLE {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/lifestyle.jpg);

        }
        &.term-ARIA {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/lifestyle.jpg);

        }
        &.term-CIBO {

            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/food.jpg);

        }
        &.term-NERD {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/nerd.jpg);


        }
        &.term-BAMBINI {
            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/bambini.jpg);


        }
        &.term-ANIMALI {

            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/animali.jpg);

        }
        &.term-SPORT {

            background-image: url(/wp-content/themes/nl_periperi/dist/images/terms/sport.jpg);

        }

    }
    .em-location-map-container {
        height:460px !important;
    }
}

