.nl_item {
    &__event-featured {
        background-size:cover !important;
        background-position:center center !important;
        position: relative;
        height: 360px;
        @media (max-width:767px) {
            height: 220px;

        }

        &--small {
            height: 190px;
            background-size:cover !important;
            background-position:center center !important;
            position: relative !important;

            article {
                position: relative !important;
                height: 190px;
                bottom: 0 !important;

                header {
                    position: absolute;
                    top: 30px;
                    right: 10px;
                    left: 18px;
                }
                footer {
                    position: absolute;
                    bottom: 8px;
                    left: 16px;
                    right: 10px; 
                }
            }
        }

    } 


    &__sliderone  {
        height:570px;
        margin-bottom:0;
        position: relative !important;

        article {

            bottom: 30px !important;
            position: absolute;

            header {

            }
            footer {

            }
        }
    }


    &__news-featured {
        background-size: cover;
        background-position: center center;

        position: relative;
        height: 230px;
        margin-bottom: 30px;


        article {
            position: absolute;
            bottom: 0px !important;
            width: 100%;
            h3 {
                padding: 40px 0px 5px;

            }
            .data-news {
                display: inline-table;
                background: #edc953;
                padding: 10px;
                position: absolute;
                right: 0;
                left: 0;

                top: -50px;
            }
        }
    }
    &__databox {

        .mese {


        }
    }
    article {
        position: absolute;
        bottom: 15px;
        padding: 0 15px;
        width: 100%;


        a{
            color: $light;

        }

        h3 {
            text-transform: uppercase;
        }
    }
    .nl_categoria-evento {
        position: absolute;
        top: 5px;
        right: 15px;
        ul.event-categories {
            padding: 2px 7px !important;
            font-family: $family-title;
            font-weight: 700;  
            text-transform: uppercase;
            font-size: $rem11;
            margin-bottom: 0 !important;
            list-style: none;
            display: inline-block;
            float: right;
            background-color: #edc953;
            padding: 5px;
            margin-top: 13px;
            a {
                color: $dark;
            }
        }
    }

    &__foto-week-end {
        @media(max-width:991px) {
            height: 140px !important;
        }
    }


}
.event-link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    &:hover {
        background-color:rgba( $accent, .3 );



    }
} 
.crsl-label {
    position: absolute; 
    top: -15px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #eac14a;
    max-width: 130px;
    padding: 15px;
    height: 50px;
    display: block;
    z-index: 3;
    font-weight: 700;

}

/* .nl_item__databox {
  @media (max-width:991px) {
      display:none;
}
}
*/

.item-storia {
    background:white;
    /*  box-shadow: 0 0 50px 0px #d4d4d4;*/

    .section__heading {
        padding:20px;

        &-1 { border-top: 3px solid #17a2b8;}
        &-2 { border-top: 3px solid #b81786;}
        &-3 { border-top: 3px solid #3fb817;}
    }
    .content {
        padding:0 20px 20px 20px;

    }
}