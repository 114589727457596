.section__calendario {
    .modal {
        background:$accent-overlay;
    }
    .modal-content {
        background: transparent;
        border:none;
    }
    .modal-body {
        @media (max-width:991px) {
 
        }
    }
    .modal-header {
        border:none;
    }
    .modal-dialog {
        max-width: 100%;
    }
    .em-calendar {
        width:100%;
   
        .em-calnav-next {
            color:$light;
            &::after {
        
            }
 
        }
        .em-calnav-prev {
             color:$light;
            &::before {
        
            }
 
 
        }
    }
    td {
        font-size:$rem38;
        font-family: $family-title;
        font-weight: 900;
        @media (max-width:991px) {
            font-size:20px;

        }

        &.month_name {
            color:$light !important;
            
        }
     

        &.eventful,&.eventless,&.eventful-pre {
            border:1px solid white;
            color:$light !important;
            a {
                color:$color_1 !important;
                
            }
        }
        &.eventful-today {
            background-color:$color_1 !important;
            border:1px solid $color_1 !important;
            
            a {
                color:$light !important;
                
            }
            
        }
        
    }
    .days-names {
        color:$light;
        text-transform:uppercase;
        td {
            padding-bottom: 30px;
            padding-top: 30px;
            
        }
    }
}

