.section {



    &__calendario {
        #calendario.modal-open.modal {
            background: $accent_overlay;
        }
    } 
    &__mappa {
        background-image:url('/wp-content/themes/nl_periperi/dist/images/mappa_catania.jpg') ;
        //  background-attachment: fixed;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        height: 600px;
        @media (max-width: 991px) { 
            display: none;
        } 

    } 


    &__storie {
        background-image:url('/wp-content/themes/nl_periperi/dist/images/duotone.png') ;
        background-attachment:fixed;
        /*        margin-top: 50px;*/
        .container {
            /*            margin-top: -150px;*/

        }
        .box_mese {
            @include box_mese;
        }
    }





    &__carsl--events-feat{
        .carsl--event-main  {
            display: block;

            @media (max-width: 991px) { 
                display: none;
            }
        }
        .carsl--event-main_mobile  {
            display: none;

            @media (max-width: 991px) { 
                display: block;
            }
        }
        .nl_item__event-featured {
            margin-bottom:0;

        }
    }
    &__carsl--events-widget {
        position: relative;
        .nl_item__event-featured {
            border:2px solid $color_1 !important;
            box-shadow: 0 0 0 6px $light inset; 

            article {
                padding: 0 35px;

            }            
        }

    }
    &__heading {
        /*         border-top: 3px solid #2a3544;
          padding-top: 20px;
        */      
    }       

    &__newsletter {
        background-image:  linear-gradient(
            rgba(42, 53, 68,0.8), 
            rgba(42, 53, 68,0.8)
            ),url('/wp-content/themes/nl_periperi/dist/images/bg-newsletter.jpeg');
        background-size: cover;
        background-position: center center;
        background-attachment: fixed;
        height: 570px;
        padding-right: 30px;
        padding-left: 30px;
        @media only screen and (max-width:991px) {
            background-attachment: unset !important;

        } 
    }


    &__news {
        @media only screen and (max-width:991px) {
            background-size: cover !important;

        } 
        /*        background-image:url('/wp-content/themes/nl_periperi/dist/images/bg-news.svg');*/
        background-color: #f3f3f3;
        background-repeat: no-repeat;
        background-position: center top;
        margin-top: -10px;




        .nl_item__event-featured {
            margin-bottom:30px;
        }
    }
} 



