/*REM Sizes*/

$rem11:0.6875rem; /* 12px */

$rem12:0.75rem; /* 12px */

$rem13:0.8125rem; /* 13px */

$rem14:0.875rem; /* 14px */

$rem16:1rem; /* 16px */

$rem18:1.125rem; /* 18px */

$rem20:1.25rem; /* 20px */

$rem22:1.375rem; /* 22px */

$rem26:1.625rem; /* 26px */

$rem28:1.75rem; /* 26px */

$rem34:2.125rem; /* 34px */

$rem38:2.375rem; /* 34px */

$rem48:3rem; /* 48px */

$rem72:4.5rem; /* 72px */


/*FONTS*/ 

$family-title: 'Montserrat', sans-serif;

/*Enqueued by Joyn*/

$family-p: 'Source Sans Pro', sans-serif;

///*Colors variables*/

$color_light: #fff;
$color_dark: #4a4a4a;
$color_dark_blu: #24414b;
$color_light_blu: #0070c9;
$color_green_light: lighten(#0AABB5,5%);
$color_green: #0AABB5;
$color_green_dark: #077177;
$color_orange_light: lighten(#F29D00,5%);
$color_orange: #F29D00;
$color_orange_dark: darken(#F29D00,5%);
$color_azure_light: #F5F8FA;
$color_azure: #d8e4ed;
$color_gray: #d8d8d8;
$color_purple : #551a8b;
$shadow_dark: rgba(#000,.25);
$breadcrumbs_divider: #dedede;

$light: $color_light;
$dark: $color_dark;
$bg_accent_dark: $color_dark_blu;
$accent_light: $color_azure_light;
$accent_alt: $color_orange;
$accent_alt-hover:#F4B033;
$accent_dark: $color_green_dark;
$link_hover: $color_green_dark;
$link_visited: $color_purple;
$link_active: $color_green_dark;
$btn_accent_hover: $color_green_light;
$btn_accent_visited: $color_green;
$btn_accent_focus: $color_green;
$btn_accent_active: $color_green_light;

$btn_alt_hover: $color_orange_light;
$btn_alt_visited: $color_orange;
$btn_alt_focus: $color_orange;
$btn_alt_active: $color_orange_light;

$title_dark: $color_green_dark;

.accent {
    color:$accent;
}
.light {
    color:$color-light !important;
}

.dark {
    color:$dark;
}

.title {
    font-family: $family-title; 
    line-height: 1.4 !important;
    margin: 0;
    padding: 0;
    letter-spacing: 0px;
    margin-bottom: 10px;
    font-weight: 400; 

    &--extralarge {
        font-size: $rem48;
        font-weight: 700; 
        line-height: 1.2 !important;


    }
    &--large {
        font-size: $rem28;
        font-weight: 700;
        text-transform:uppercase !important; 

    }
    &--normal {
        font-size: $rem26;
        font-weight: 700;
        text-transform:uppercase !important; 

    }
    &--small {
        line-height:1.2 !important;
        font-size: $rem22;
        font-weight: 700;
        text-transform:uppercase !important; 

    }
    &--extrasmall {
        font-size: $rem14;
        text-transform: uppercase;
        font-weight: 600;

    }
}


p {   
    color:$dark !important;

    a {
        color:$accent;

    }
}

.p {
    margin: 0;
    padding: 0;
    font-family: $family-p;
    line-height: 1.4 !important;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: 0px;

    &--large {
        font-size: $rem22;
    }
    &--normal {
        font-size: $rem16;
    }
    &--extrasmall {
        font-size: $rem12;
    }
}



.link {
    color: $accent;
    font-family: $family-p;
    font-weight: 400;
    line-height: 1.4 !important;
    text-decoration: underline;
    letter-spacing: 0px;

    &--extrasmall {
        font-size: $rem12;
        line-height: 2 !important;

        text-decoration: none !important;
    }

    &--large {
        font-size: $rem22;
    }
    &--normal {
        font-size: $rem16;
    } 
    &--title {
        font-weight: 700;
        color: $accent !important;
    }
    &--list {
        color: #fff !important;
    }

    &--title,
    &--list {
        &:hover {
            text-decoration: underline !important;
        }        
    }

    &:hover {
        /*         color: $link_hover;
        */        text-decoration: underline;
    }
    &:visited {
        color: $link_visited;
        text-decoration: underline;
    }
    &:focus,
        &:active {
        color: $accent;
        text-decoration: underline;
    }
}

.over-title {
    font-family: $family-title;
    text-transform:uppercase;
    margin: 0;
    padding: 0;
    color: $dark; 
    line-height: 1.4;
    letter-spacing: 4px;


    &--normal {
        font-weight: 400;
        font-size: $rem20;
    }
}


.btn-storie {
    display: block;
    text-align: center;
    background: #2a3544;
    color: white;
    text-transform: uppercase;
    padding: 15px 0;
    font-weight: 700;

    &:hover {
        background: #eac14a;
        color:white;
        text-decoration:none;
    }
}


.btn {
    line-height: 1.4;
    font-family:$family-p;
    //box-shadow: none !important;


    &-lg,
    &-default {
        background-color:$accent;
        border:none;
        color:$light;
        font-size:$rem16;

        &:hover,
            &:hover:visited {
            background-color: darken($color_1,10%);
            color:$light;

        }
        &:active {
            background-color: $btn_accent_active;
            color:$light;
        }
        &:focus {
            background-color: $btn_accent_focus;
            color:$light;
        }
        &:visited {
            background-color: $btn_accent_visited;
            color:$light;
        }
    }

    &-lg {
        padding: 15px 30px;
    }

    &--secondario-testata {
        background-color: $accent_alt !important;
        color: $light;

        &:hover {
            background-color: $accent_alt-hover !important;
            color:$light;

        }

    }
    &--calendar {
        border-radius:20px;
        box-shadow: inset 0 0 0 2px #fff;
        margin: 0 5px;
        padding: 10px 12px;
        font-size: 14px;


        &:hover {
            box-shadow: 0 0 0 1px darken($color_1,10%) inset;

        }

        .fa-calendar-alt {
            font-size: 18px;
        }

        &.heartbeat {
            -webkit-animation: flicker-1 2s linear 10s 5 both;
            animation: flicker-1 2s linear 10s 5 both;

            @-webkit-keyframes heartbeat {
                0%,
                100% {
                    opacity: 1;
                }
                41.99% {
                    opacity: 1;
                }
                42% {
                    opacity: 0;
                }
                43% {
                    opacity: 0;
                }
                43.01% {
                    opacity: 1;
                }
                47.99% {
                    opacity: 1;
                }
                48% {
                    opacity: 0;
                }
                49% {
                    opacity: 0;
                }
                49.01% {
                    opacity: 1;
                }
            }
            @keyframes flicker-1 {
                0%,
                100% {
                    opacity: 1;
                }
                41.99% {
                    opacity: 1;
                }
                42% {
                    opacity: 0;
                }
                43% {
                    opacity: 0;
                }
                43.01% {
                    opacity: 1;
                }
                47.99% {
                    opacity: 1;
                }
                48% {
                    opacity: 0;
                }
                49% {
                    opacity: 0;
                }
                49.01% {
                    opacity: 1;
                }
            }


        }


    }



    &--outline-primary {
        background: transparent;
        box-shadow: 0 0 0 2px $accent_dark inset;
        color: $accent_dark;

        &:hover {
            box-shadow: 0 0 0 2px $accent_alt inset;
            background: transparent !important;
            color:  $accent_dark !important;

        }
        &:visited {
            box-shadow: 0 0 0 2px $accent_alt inset;
            background: transparent !important;
            color:  $accent_dark;

        }
        &:focus { 
            background: transparent !important;
            color:  $accent_dark !important;

        }
    }

    &--outline-light {
        background: transparent;
        box-shadow: 0 0 0 2px $light inset;
        color: $light;

        &:hover {
            box-shadow: 0 0 0 2px $accent inset;
            background: transparent !important;
        }
        &:focus { 
            background: transparent !important;

        }
    }

    &--inline {
        margin-left:10px;
        margin-right:10px;

    }


    &--space-10 {

        margin-top: 10px;

    }

    &.btn-search-header {
        &:hover {
            background-color: $color_1;
        }
    }
}


@media screen and (max-width: 767px) {


    .title--extralarge {
        font-size: 32px;
    }
    .title--large {
        font-size: 22px;
    }
    .title--small {
        font-size: 18px;
    }
    .p--large {
        font-size: 18px;
    }

} 

@media screen and (max-width: 991px) and (min-width: 768px) {
    html {
        font-size: 16px;
    }

}

@media screen and (max-width: 991px) and (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    html {
        font-size: 16px;
    } 


}