.nl_footer {
    background: darken($accent,5%);
    font-size:$rem12;
    padding-bottom:5px;
img {
    height:50px;
}
    ul {
        padding-left: 0 !important;

        li {
        
            a {
                 color:white;
                 &:hover {
                     color:$color_1;
                     text-decoration: none;
                 }
            }
        }
    }
    a.crediti  {
        color:$color_1 !important;
    }
}  